<template>
  <div id="user-share-data">
    <div class="user-header">
      <img class="share-log" src="../../../../static/img/userData/collection_log.svg" alt="" />
      <span @click="myCollectionMenu"
        >{{ getString(strings.User_My_Collection) }}
      </span>
      <span @click="myCollectionMore" style="margin-left: 10px">{{
        collectionMoreName
      }}</span>
      <span style="margin-left: 1px">{{ menuName }}</span>
      <span v-if="this.$router.history.current.name != 'meMoreCollection'">({{ myCollectionCount }})</span
      >
      <div
        v-if="this.$router.history.current.name != 'meMoreCollection'"
        class="user-share-more"
        @click="getUserShareLink"
      >
        <span style="font-size: 14px;color: #666666;">{{
          getString(strings.User_More_Message)
        }}</span>
        <img src="../../../../static/img/userData/look_more_mind.svg" alt="" />
      </div>
    </div>

    <!-- 分类 -->
    <div
      v-if="
        this.$router.history.current.name != 'LookMessage' &&
        this.$router.history.current.name != 'Details' &&
        this.$router.history._startLocation != '/Home/LookMessage'
      "
      class="share-mind-header"
    >
      <div
        class="share-mind-and-link"
        v-for="(item, index) in shareTitle"
        :key="index"
        :class="shareTileAccout == index ? 'set-title-style' : ''"
        @click="setTitleStyle(index)"
      >
        {{ getString(item) }}
      </div>
      <div class="share-new-profile-box">
        <div
          class="share-new-profile"
          @click="newProfileToTemplate('newProFile')"
        >
          <img
            src="../../../../static/img/userData/new_profile_logo.png"
            alt=""
          />
          <span>{{ getString(strings.User_New_Group) }}</span>
        </div>
      </div>
    </div>
    <!-- 收藏夹列表页 -->
    <transition name="slide-fade">
      <div class="share-content share-detail" v-if="routerName == 0">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData.collections"
          :key="index"
        >
          <div
            class="share-collection-profile"
            @click="collectionToMoreDetial(item.id, item.name, index)"
          >
            <img
              src="../../../../static/img/userData/collection_proflie_logo.png"
              alt=""
            />
          </div>
          <div class="share-text">
            <div class="share-mind-titel">
              <div
                style="width: 100%"
                @click="collectionToMoreDetial(item.id, item.name, index)"
              >
                {{ item.name }}
              </div>
              <a-popover
                v-model="item.visible"
                placement="bottomLeft"
                trigger="click"
              >
                <template slot="content">
                  <div
                    class="share-delete-content"
                    @click="deleteCollectionProfile(item.id,index)"
                  >
                    <img
                      src="../../../../static/img/userData/delete_logo.png"
                      alt=""
                    />
                    <span>{{
                      getString(strings.Mind_Mindmap_Deleted_Forever)
                    }}</span>
                  </div>
                  <div
                    class="share-delete-content"
                    @click="
                      updateCollectionProfileName(
                        item.id,
                        'updataName',
                        item.name,
                        index
                      )
                    "
                  >
                    <img
                      src="../../../../static/img/userData/updata_collection_name.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Folder_Rename) }}</span>
                  </div>
                  <div
                    class="share-delete-content"
                    @click="
                      updateCollectionProfileLimit(
                        item.id,
                        item.isPrivate,
                        item.canBeSaved,
                        index
                      )
                    "
                  >
                    <img
                      src="../../../../static/img/userData/updata_collection_root.png"
                      alt=""
                    />
                    <span>{{
                      getString(strings.User_Folder_Rename_Root)
                    }}</span>
                  </div>
                </template>
                <a-button
                  ><img
                    src="../../../../static/img/userData/details_logo.png"
                    alt=""
                /></a-button>
              </a-popover>
            </div>
            <div
              class="share-look-data"
              @click="collectionToMoreDetial(item.id, item.name)"
            >
              <div class="share-look-time">
                {{ timestampToTimeDHM(item.createTime) }}
              </div>
              <div class="share-look-isPrivate" v-if="item.isPrivate">
                <a-icon type="lock" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 更多模板 -->
    <transition name="slide-fade">
      <div class="share-content share-detail" v-if="routerName == 1">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData"
          :key="index"
        >
          <div
            class="share-collection-profile share-color"
            @click="moreShareOrcollectionToLink(item)"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-text">
            <div class="share-mind-titel">
              <div
                style="width: 100%"
                @click="moreShareOrcollectionToLink(item)"
              >
                {{ item.title }}
              </div>
              <a-popover
                v-model="item.visible"
                placement="bottomLeft"
                trigger="click"
              >
                <template slot="content">
                  <div
                    class="share-delete-content"
                    @click="deleteCollectItem(item.id, index)"
                  >
                    <img
                      src="../../../../static/img/userData/delete_logo.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Remove_From_List) }}</span>
                  </div>
                  <div
                    class="share-delete-content"
                    @click="moveCollectionTemplate(item, index)"
                  >
                    <img
                      src="../../../../static/img/userData/move_collection_newadd.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Move_To) }}</span>
                  </div>
                </template>
                <a-button
                  ><img
                    src="../../../../static/img/userData/details_logo.png"
                    alt=""
                /></a-button>
              </a-popover>
            </div>
            <div
              class="share-look-data"
              @click="moreShareOrcollectionToLink(item)"
            >
              <div class="share-look-time">
                {{ timestampToTimeDHM(item.createTime) }}
              </div>
              <div class="share-look-count" v-if="shareTileAccout != 0">
                <img
                  src="../../../../static/img/userData/user_mind_lookcount.svg"
                  alt=""
                />
                85
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 更多链接 -->
    <transition name="slide-fade">
      <div class="share-content share-detail" v-if="routerName == 2">
        <div
          class="share-cover"
          v-for="(item, index) in initUserCollectionData"
          :key="index"
        >
          <div
            class="share-collection-profile share-color"
            :style="'background-color:' + colorList[index] + ';'"
            @click="moreShareOrcollectionToLink(item)"
          >
            <img src="../../../../static/img/userData/link_logo.png" alt="" />
          </div>
          <div class="share-text">
            <div class="share-mind-titel">
              <div
                style="width: 100%"
                @click="moreShareOrcollectionToLink(item)"
              >
                {{ item.title }}
              </div>
              <a-popover
                v-model="item.visible"
                placement="bottomLeft"
                trigger="click"
              >
                <template slot="content">
                  <div
                    class="share-delete-content"
                    @click="moveCollectionTemplate(item, index)"
                  >
                    <img
                      src="../../../../static/img/userData/move_collection_newadd.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Move_To) }}</span>
                  </div>
                  <div
                    class="share-delete-content"
                    @click="deleteLinkAccout(item.id, index)"
                  >
                    <img
                      src="../../../../static/img/userData/delete_logo.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Deleted_Link) }}</span>
                  </div>
                </template>
                <a-button
                  ><img
                    src="../../../../static/img/userData/details_logo.png"
                    alt=""
                /></a-button>
              </a-popover>
            </div>
            <div
              class="share-look-data"
              @click="moreShareOrcollectionToLink(item)"
            >
              <div class="share-look-time">
                {{ timestampToTimeDHM(item.createTime) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 主页展示 -->
    <div class="share-content" v-if="routerName == 3">
      <div
        class="share-cover"
        v-for="(item, index) in initUserCollectionData.items"
        :key="index"
        @click="moreShareOrcollectionToLink(item)"
      >
        <div
          class="share-collection-profile share-color"
          :style="'background-color:' + colorList[index] + ';'"
        >
          <img
            src="../../../../static/img/userData/link_logo.png"
            v-real-img="item.cover"
            alt=""
          />
        </div>
        <div class="share-text">
          <div class="share-mind-titel">
            <div>{{ item.title }}</div>
          </div>
          <div class="share-look-data">
            <div class="share-look-time">
              {{ timestampToTimeDHM(item.createTime) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 风暴 -->
    <transition name="slide-fade">
      <div class="share-potato-chips" v-if="routerName == 4">
        <div
          class="share-pc-box"
          v-for="(item, index) in initUserCollectionData"
          :key="index"
        >
          <div
            class="share-pc-cover"
            @click="moreShareOrcollectionToLink(item)"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-pc-text">
            <div class="share-pc-title">
              <span>{{ item.title }}</span>
            </div>
            <div class="share-pc-text-content">
              <span>{{ item.subtitle }}</span>
            </div>
            <div class="share-pc-right-detail">
              <a-popover
                v-model="item.visible"
                placement="bottomLeft"
                trigger="click"
              >
                <template slot="content">
                  <div
                    class="share-delete-content"
                    @click="moveCollectionTemplate(item, index)"
                  >
                    <img
                      src="../../../../static/img/userData/move_collection_newadd.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Move_To) }}</span>
                  </div>
                  <div
                    class="share-delete-content"
                    @click="deleteStormAccout(item.itemId, index)"
                  >
                    <img
                      src="../../../../static/img/userData/delete_logo.png"
                      alt=""
                    />
                    <span>{{ getString(strings.User_Deleted_Link) }}</span>
                  </div>
                </template>
                <a-button
                  ><img
                    src="../../../../static/img/userData/details_logo.png"
                    alt=""
                /></a-button>
              </a-popover>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- 数据为空 -->
    <div
      v-if="initCollectionData"
      class="user-share-data-null-page"
      :class="
        this.$router.history.current.name == 'meMoreCollection'
          ? 'user-share-data-null'
          : 'user-share-data-null-page'
      "
    >
      <div class="data-null">
        <img
          style="width: 120px;"
          src="../../../../static/img/userData/user_collection_null_logo.png"
          alt=""
        />
        <p>{{getString(strings.User_Collction_Is_Null)}}</p>
      </div>
    </div>
    <!-- 删除模态框 -->
    <a-modal
      class="report-crisps-model"
      :centered="true"
      v-model="reportCrispsDelete"
      :title="getString(strings.Mind_Mindmap_Deleted_Forever)"
      :cancelText="getString(strings.Global_Cancel)"
      :okText="getString(strings.Global_Ok)"
      @ok="commentCrispsDeleteHandleOk"
    >
      <div class="report-crisps-style-box">
        <div
          class="report-crisps-style"
          style="
            font-size: 18px;
            font-family: Noto Sans SC;
            text-align: center;
            display: block;
          "
        >
          {{ getString(strings.User_Deleted_Storm_Tips) }}
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  postUserDeleteCollection,
  postUserDeleteCollectItem,
  postUserMyCollectedItems,
  postUserMyCollections,
  postUserMyCollects,
} from "../../../common/netWork/base_api";

import dataTimeToFormats from "../../../utils/timestampToTime";
import Logger from "../../../utils/Logger";
import HttpServerConfig from "../../../common/HttpServerConfig";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import { mapMutations } from "vuex";
import { postCrispsUncollect } from "../../../common/netWork/crisps_api";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";

import crisps from "../../../assets/css/crisps/crisps.less";
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}

export default {
  data() {
    return {
      shareTitle: [strings.TEMPLATE, strings.User_Message_Link, strings.Storm],
      shareTileAccout: 0,
      routerName: "", // 0 收藏夹列表 1 更多模板 2 更多链接 3 信息界面模板or链接 4风暴
      initUserCollectionData: {},
      myCollectionCount: 0,
      menuName: "", //菜单名称
      collectionMoreName: "", //更多菜单名称
      templateCollectionId: "", //收藏夹模板id
      initCollectionData: false,
      nowElectron: false,
      reportCrispsDelete: false,
      shareIdCollection: null,
      visible: false,
      colorList: [
        "#9db2c4",
        "#9179c3",
        "#e18995",
        "#ebc185",
        "#82d0dc",
        "#60a5a2",
        "#6d6a8c",
        "#f1cecc",
      ],
      strings: strings,
    };
  },
  props: {
    initUserData: {
      defualt() {
        return {};
      },
    },
  },
  created() {
    //this.postUserMyCollections();
  },
  mounted() {
    this.nowElectron = this.$tools.isElectron();
    var router = this.$router.history.current.name;
    if (router == "meMoreCollection") {
      //更多默认显示收藏夹列表
      this.routerName = 0;
      this.collectionMoreName = "> " + getString(strings.User_More_Message);
      this.postUserMyCollections();
    } else {
      //个人信息界面模板or链接
      this.routerName = 3;
    }
  },
  methods: {
    ...mapMutations([
      "hideModelLink",
      "colletionNewFileModel",
      "updateCollectionFileLimit",
      "moveCollectionTemplateToNewFile",
    ]),
    //语言包
    getString(i) {
      return getString(i);
    },
    openMind(item) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item);
    },
    //添加背景颜色
    addBackgroundColor(array) {
      if (array.length > this.colorList.length) {
        for (let index = 0; index < array.length; index++) {
          if (array.length != this.colorList.length) {
            this.colorList.push(this.colorList[index]);
          }
        }
      }
    },
    //title
    setTitleStyle(index) {
      this.shareTileAccout = index;
      if (index == 2) {
        this.routerName = 0;
        this.postUserMyCollections();
      } else {
        this.routerName = 0;
        this.postUserMyCollections();
      }
      // console.log(this.routerName);
    },
    //根据title请求
    postUserMyCollections() {
      postUserMyCollections(
        { type: this.shareTileAccout, lastCollectionId: "" },
        (initCollectionData) => {
          this.menuName = "";
          this.initUserCollectionData = initCollectionData;
          if (this.initUserCollectionData.collections.length > 0) {
            this.initCollectionData = false;
          } else {
            this.initCollectionData = true;
          }
          this.addBackgroundColor(this.initUserCollectionData.collections);
        }
      );
    },
    //根据点击收藏夹请求
    collectionToMoreDetial(id, name) {
      if (name != undefined) {
        this.templateCollectionId = { id, name };
      }
      postUserMyCollectedItems(
        {
          collectionId: id,
          lastId: "",
        },
        (initUserCollectionData) => {
          // 0-模板收藏夹 1-链接收藏夹 2-风暴收藏夹
          const httpType = this.shareTileAccout;
          switch (httpType) {
            case 0:
              this.routerName = 1;
              break;
            case 1:
              this.routerName = 2;
              break;
            case 2:
              this.routerName = 4;
              break;
            default:
              break;
          }
          if (initUserCollectionData.items.length > 0) {
            this.initCollectionData = false;
            let itemsLength = initUserCollectionData.items.length;
            let items = initUserCollectionData.items;

            for (let i = 0; i < itemsLength; i++) {
              var cover = items[i].cover;
              initUserCollectionData.items[i].cover = httpImageToPrefix(cover);
            }

            var that = this;
            this.initUserCollectionData = items.filter(function (
              element,
              index,
              self
            ) {
              return self[index].type == that.shareTileAccout;
            });
          } else {
            this.initUserCollectionData = [];
            this.initCollectionData = true;
          }
          //title菜单
          this.menuName = " > " + name;
        }
      );
    },
    //获取风暴

    //获取收藏or链接
    getUserCollectionData() {
      let itemsLength = this.initUserCollectionData.items.length;
      let items = this.initUserCollectionData.items;
      for (let i = 0; i < itemsLength; i++) {
        var cover = items[i].cover;
        this.initUserCollectionData.items[i].cover = httpImageToPrefix(cover);
      }
      this.myCollectionCount = this.initUserCollectionData.items.length;
    },
    //更多链接
    getUserShareLink() {
      this.$router.push({
        path: "/Home/meMoreCollection",
        query: { id: this.initUserCollectionData.userId },
      });
    },
    //时间转化
    timestampToTimeDHM(timestamp) {
      return dataTimeToFormats.timestampToTimeDHM(timestamp);
    },

    //新建文件夹
    newProfileToTemplate(style) {
      const type = this.shareTileAccout;
      this.colletionNewFileModel({ accout: true, data: { type, style } });
    },
    //重命名文件夹
    updateCollectionProfileName(id, style, name, index) {
      this.initUserCollectionData.collections[index].visible = false;
      this.colletionNewFileModel({ accout: true, data: { id, style, name } });
    },
    //修改文件夹权限
    updateCollectionProfileLimit(id, isPrivate, canBeSaved, index) {
      this.initUserCollectionData.collections[index].visible = false;
      this.updateCollectionFileLimit({
        accout: true,
        data: { id, isPrivate, canBeSaved },
      });
    },
    //移动文件夹
    moveCollectionTemplate(item, index) {
      this.initUserCollectionData[index].visible = false;
      const id = this.templateCollectionId.id;
      this.moveCollectionTemplateToNewFile({
        accout: true,
        data: { id, item },
      });
    },
    //确认删除
    commentCrispsDeleteHandleOk() {
      // 0 收藏夹 1 更多模板 2 更多链接 3 信息界面模板or链接 4风暴
      if (this.routerName == 0) {
        postUserDeleteCollection(
          { collectionId: this.shareIdCollection },
          (res) => {
            this.reportCrispsDelete = false;
            this.$message.success(getString(strings.Message_Tips_Dlt_Success));
            this.postUserMyCollections();
          }
        );
      } else if (this.routerName == 1) {
        postUserDeleteCollectItem(
          { collectItemId: this.shareIdCollection },
          (res) => {
            this.reportCrispsDelete = false;
            this.$message.success(getString(strings.Message_Tips_Dlt_Success));
            this.collectionToMoreDetial(
              this.templateCollectionId.id,
              this.templateCollectionId.name
            );
          }
        );
      } else if (this.routerName == 2) {
        postUserDeleteCollectItem(
          { collectItemId: this.shareIdCollection },
          (res) => {
            this.reportCrispsDelete = false;
            this.$message.success(getString(strings.Message_Tips_Dlt_Success));
            this.collectionToMoreDetial(
              this.templateCollectionId.id,
              this.templateCollectionId.name
            );
          }
        );
      } else if (this.routerName == 4) {
        postCrispsUncollect(
          { crispsId: this.shareIdCollection },
          (res) => {
            this.reportCrispsDelete = false;
            this.$message.success(getString(strings.Message_Tips_Dlt_Success));
            // console.log(
            //   this.templateCollectionId.id,
            //   this.templateCollectionId.name
            // );
            this.collectionToMoreDetial(
              this.templateCollectionId.id,
              this.templateCollectionId.name
            );
          },
          (error) => {
            this.$message.error(error.desc);
          }
        );
      }
    },
    //删除收藏夹
    deleteCollectionProfile(id, index) {
      this.reportCrispsDelete = true;
      this.shareIdCollection = id;
      this.initUserCollectionData.collections[index].visible = false;
    },
    //删除模板
    deleteCollectItem(id, index) {
      this.reportCrispsDelete = true;
      this.shareIdCollection = id;
      this.initUserCollectionData[index].visible = false;
    },
    //删除链接
    deleteLinkAccout(id, index) {
      this.reportCrispsDelete = true;
      this.shareIdCollection = id;
      this.initUserCollectionData[index].visible = false;
    },
    //删除风暴
    deleteStormAccout(id, index) {
      this.reportCrispsDelete = true;
      this.shareIdCollection = id;
      this.initUserCollectionData[index].visible = false;
    },
    //跳转个人信息详情
    myCollectionMenu() {
      this.$router.push("/Home/Details");
    },
    //跳转更多
    myCollectionMore() {
      const titleId = this.shareTileAccout;
      switch (titleId) {
        case 0:
          this.routerName = 0;
          this.postUserMyCollections();
          break;
        case 1:
          this.routerName = 0;
          this.postUserMyCollections();
          break;
        case 2:
          this.routerName = 0;
          this.postUserMyCollections();
          break;
        default:
          break;
      }
      this.menuName = "";
    },
    //跳转模板0 链接1 风暴2
    moreShareOrcollectionToLink(item) {
      if (this.nowElectron) {
        if (item.type == 0) {
          this.openMind("/template/?id=" + item.itemId);
        } else if (item.type == 1) {
          this.openMind("/s/" + item.itemId);
        } else if (item.type == 2) {
          this.openMind("/c/" + item.itemId);
        }
      } else {
        if (item.type == 0) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/template/?id=" + item.itemId,
          });
          newWindow.location = routerData.href;
        } else if (item.type == 1) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/s/" + item.itemId,
          });
          newWindow.location = routerData.href;
        } else if (item.type == 2) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/c/" + item.itemId,
          });
          newWindow.location = routerData.href;
        }
      }
    },
  },
  watch: {
    initUserData(newCollection) {
      this.initUserCollectionData = newCollection;
      if (this.initUserCollectionData.items.length > 0) {
        this.initCollectionData = false;
      }else{
        this.initCollectionData = true
      }
      this.getUserCollectionData();
    },
    $route(to, from) {
      var router = to.path;
      var fromRouter = from.path;
    },
    "$store.state.colletionNewFileModel.accout"(newSuccess) {
      if (!newSuccess) {
        if (this.routerName == 0) {
          this.postUserMyCollections();
        } else if (this.routerName == 1) {
          this.collectionToMoreDetial(
            this.templateCollectionId.id,
            this.templateCollectionId.name
          );
        }
      }
    },
    "$store.state.colletionLimitFileModel.accout"(newSuccess) {
      if (!newSuccess) {
        this.postUserMyCollections();
      }
    },
    "$store.state.colltionMoveFile.accout"(newSuccess) {
      if (!newSuccess) {
        this.collectionToMoreDetial(
          this.templateCollectionId.id,
          this.templateCollectionId.name
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
#user-share-data {
  background: #fff;
  padding: 20px;
  .user-header {
    height: 48px;
    display: flex;
    align-items: center;
    .share-log{
      margin: 20px 10px 20px 10px;
    }
    img {
      margin: 20px 10px 20px 20px;
    }
    span {
      font-size: 18px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      color: #333;
      opacity: 1;
      cursor: pointer;
    }
    .user-share-more {
      position: absolute;
      right: 27px;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      span {
        transition: 0.3s;
      }
    }
    .user-share-more:hover {
      // span {
      //   color: #fd492b;
      //   transition: 0.3s;
      // }
      // img {
      //   width: 14px;
      //   height: 15px;
      //   filter: drop-shadow(#fd492b 100px 0);
      //   transform: translateX(-100px);
      //   overflow: hidden;
      // }
    }
  }
  // 分类
  .share-mind-header {
    display: flex;
    border-bottom: 3px solid #f0f2f8;
    height: 40px;
    margin-top: 20px;
    position: relative;
    .share-new-profile-box {
      position: absolute;
      right: 20px;
      .share-new-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
    }
    .share-mind-and-link {
      font-size: 16px;
      height: 38px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      opacity: 1;
      text-align: center;
      cursor: pointer;
      margin: 0 30px;
    }
    .set-title-style {
      border-bottom: 3px solid #fd492b;
      color: #fd492b;
      display: flex;
      justify-content: center;
    }
  }
  .share-content {
    width: 1590px;
    min-width: 242px;
    margin-top: 12px;
    display: flex;
    overflow: hidden;
    .share-cover {
      width: 180px;
      height: 222px;
      border-radius: 4px 4px 0px 0px;
      background: rgba(0, 0, 0, 0);
      box-shadow: 0px 3px 6px rgba(80, 80, 80, 0.16);
      margin: 12px 10px;
      cursor: pointer;
      transition: 0.5s;
      .share-collection-profile {
        width: 180px;
        height: 160px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;
        img {
          transition: 0.5s;
        }
      }
      .share-text {
        width: 180px;
        height: 62px;
        border-radius: 4px 4px 0px 0px;
        .share-mind-titel {
          margin: 5px 0px 10px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          width: 168px;
          height: 20px;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            color: #666666;
            font-size: 14px;
          }
          .share-mind-delete {
            width: 20px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
          }
          button {
            border: none;
            height: 20px;
            padding: 0px 10px;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
        .share-look-data {
          margin: 3px 6px 3px 10px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          .share-look-time{
            color: #999999;
            font-size: 12px;
          }
          .share-look-count{
            display: flex;
            align-items: center;
            img{
              margin-right: 6px;
            }
          }
        }
        .share-mind-titel-look {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
    }
    .share-cover:hover {
      box-shadow: 5px 8px 13px rgba(80, 80, 80, 0.16);
      background-color: rgba(0, 0, 0, 0.06);
      transition: 0.5s;
      img {
        transition: 0.5s;
        transform: scale(1.1);
      }
    }
  }
  .share-detail {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
  }
  .share-potato-chips {
    width: 100%;
    margin: 20px 20px;
    .share-pc-box {
      display: flex;
      margin-top: 20px;
      .share-pc-cover {
        width: 140px;
        height: 126px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0);
        box-shadow: 0px 3px 6px #bebebe;
        opacity: 1;
        border-radius: 10px;
      }
      .share-pc-cover img {
        transition: 0.5s;
      }
      .share-pc-cover img:hover {
        transform: scale(1.1);
        transition: 0.5s;
      }
      .share-pc-text {
        width: 100%;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .share-pc-title {
          min-width: 162px;
          height: 25px;
          font-size: 18px;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 25px;
          color: #333333;
          opacity: 1;
          margin-top: 8px;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            -webkit-transition: 0.3s;
            transition: 0.3s;
          }
        }
        .share-pc-text-content {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
          opacity: 1;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            cursor: pointer;
          }
        }
        .share-pc-bottom {
          width: 500px;
          font-size: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
          opacity: 1;
          display: flex;
          position: relative;
          align-items: center;
          .share-pc-time {
            position: absolute;
            left: 0;
          }
          .share-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            right: 0;
            width: 288px;
            .share-details-single {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                margin-right: 10px;
              }
            }
          }
        }
        .share-pc-right-detail {
          position: absolute;
          right: 30px;
          cursor: pointer;
          button {
            border: none;
            height: 20px;
            padding: 0px 10px;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  //空数据
  .user-share-data-null-page {
    display: flex;
    justify-content: center;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      img {
        margin-bottom: 30px;
      }
    }
  }
  .user-share-data-null {
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      img {
        margin-bottom: 30px;
      }
    }
  }
}

.share-delete-button {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ant-popover-inner-content {
  // padding: 18px 23px;
  z-index: 1000 !important;
}
.share-detail-edit {
  border-radius: 10px;
}
.share-delete-content {
  display: flex;
  align-items: center;
  height: 40px;
  width: 146px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
}

// //色块
// .share-color:nth-child(1) {
//     background-color: #9db2c4;
//   }
</style>